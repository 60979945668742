<template>
  <div>
    <div class="about">
      <div class="container">
        <div class="about__banner">
          <h2 class="about__title">
            {{ $t("main.aboutTitle") }}
          </h2>
          <div class="about__body">
            <p v-html="$t('main.aboutShon1')"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="structure">
      <div class="container">
        <h2 class="structure__title">
          {{ $t("about.aboutHeading") }}
        </h2>
        <div class="structure_line">
          <div class="structure_container item-5 left">
            <div class="content">
              {{ $t("about.structure1") }}
            </div>
          </div>
          <div class="structure_container item-6 right">
            <div class="content">
              {{ $t("about.structure2") }}
            </div>
          </div>
          <div class="structure_container item-7 left">
            <div class="content">
              {{ $t("about.structure3") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.structure {
  margin: 40px 0 70px 0;
  &__title {
    text-align: center;
    font-size: 30px;
    padding-bottom: 20px;
    border-bottom: 2px solid #e66e00;
    line-height: 35px;
    color: #202020;
  }
}
.about {
  background-color: #ffffff;
  margin-bottom: 120px;
  height: 650px;
  &__banner {
    background-size: contain;
    background-image: url("../assets/images/banner_about.png");
    background-repeat: no-repeat;
    background-position: left;
    height: 555px;
    text-align: right;
    padding-top: 38px;
  }
  &__title {
    font-size: 35px;
    line-height: 41px;
    color: #202020;
    padding-bottom: 18px;
    border-bottom: 2px solid #202020;
    max-width: 631px;
    margin-left: auto;
  }
  &__body {
    padding: 47px 50px 33px 42px;
    font-size: 20px;
    text-align: left;
    background: #00303a;
    border-radius: 2px;
    font-weight: 300;
    line-height: 23px;
    color: #ffffff;
    position: relative;
    margin-top: 50px;
    max-width: 535px;
    margin-left: auto;
    &:before {
      content: "";
      display: block;
      width: 389px;
      height: 30px;
      background: #e66e00;
      border-radius: 2px;
      right: 0;
      top: -20px;
      position: absolute;
    }
  }
}
.structure_line {
  position: relative;
  max-width: 1200px;
  margin: 30px auto;
  &::after {
    content: "";
    position: absolute;
    width: 2px;
    background-color: #00303a;
    top: 20px;
    bottom: 30px;
    left: 50%;
  }
}
.structure_container {
  padding: 20px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
}
.item-5::after {
  background-image: url("../assets/icons/book.svg");
}
.item-6::after {
  background-image: url("../assets/icons/school.svg");
}
.item-7::after {
  background-image: url("../assets/icons/touch_app.svg");
}
.structure_container::after {
  content: "";
  position: absolute;
  width: 44px;
  height: 44px;
  right: -22px;
  background-color: #00303a;
  background-repeat: no-repeat;
  background-position: center;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}
.left {
  left: 0;
  text-align: right;
}
.right {
  left: 50%;
}
.right::after {
  left: -22px;
}
.content {
  padding-top: 10px;
  font-size: 20px;
  line-height: 23px;
  color: #202020;
  position: relative;
}
@media (max-width: 1200px) {
  .about {
    padding-top: 100px;
  }
}
@media (max-width: 992px) {
  .about {
    height: 600px;
    &__title {
      font-size: 25px;
      padding-top: 25px;
      line-height: 18px;
      padding-bottom: 5px;
      border-bottom: 1px solid #202020;
      width: 151px;
    }
    &__banner {
      padding-top: 0;
      height: 400px;
    }
    &__body {
      margin: 300px 0 0 0;
      font-size: 16px;
      line-height: 15px;
      width: 95%;
      padding: 20px;
      &::before {
        height: 15px;
        right: -20px;
        top: -10px;
        width: 268px;
      }
    }
  }
}
@media (max-width: 768px) {
  .content {
    font-size: 14px;
    line-height: 12px;
  }
  .structure {
    &__title {
      font-size: 18px;
      line-height: 18px;
    }
  }
  .structure_line::after {
    left: 31px;
  }

  .structure_container {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  .left::after,
  .right::after {
    left: 10px;
  }
  .right {
    left: 0%;
  }
  .left {
    text-align: left;
  }
  .about {
    height: 550px;
    padding-bottom: 10px;
    &__title {
      font-size: 15px;
      padding-top: 25px;
      line-height: 18px;
      padding-bottom: 0px;
      border-bottom: 1px solid #202020;
      width: 151px;
    }
    &__banner {
      padding-top: 0;
      height: 220px;
    }
    &__body {
      margin: 170px 0 0 0;
      font-size: 12px;
      line-height: 15px;
      width: 95%;
      padding: 20px;
      &::before {
        height: 15px;
        right: -20px;
        top: -10px;
        width: 268px;
      }
    }
  }
}
</style>
